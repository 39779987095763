.Himage{
    display: block;
    width: fit-content;
    height: auto;
    overflow: hidden;
}
.box-road{
    width: 700px;
    height: 700px;        
    background: url("../Assets/Image/HRoadmap.png") no-repeat;
    background-size: cover;
  
}
.container-road {
    position: relative;
    width: 100%;
      
  }
.container-road img {
    width: 100%;
    height: auto;
    
  }

  .container-road .btn {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #1fc050;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    text-align: center;
    margin: 4px 2px;
    border: none;
    opacity: 0.9;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 10px;
    width: 15rem;
    
  }
  .btn:hover {opacity: 1}

  @media screen and (max-width:767px) {
    .container-road .btn {
      height: 26px;
      width: 95px;
      font-size: 12px;
      top:63%;
      left: 50%;
      padding: 5px 12px;
      text-align: center;
      margin-top: -30%;
    }
    }
    /* -147px */